<template>
  <div>
    <router-view v-if="appConfig"></router-view>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert2";
import { mapGetters } from "vuex";

const cartDefaultExpirations =
  process.env.VUE_APP_CART_DEFAULT_EXPIRATIONS.split(",");
const cartDeaultDelay = process.env.VUE_APP_CART_DEFAULT_DELAY;
const _30_seconds = 10 * 1000;

export default {
  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME,
      meta: (function () {
        return [
          {
            name: "keywords",
            content: process.env.VUE_APP_NAME,
          },
          {
            name: "description",
            content: process.env.VUE_APP_NAME,
          },
        ];
      })(),

      link: [{ rel: "icon", type: "image/png", href: "/favicon.ico" }],
      script: (function () {})(),
    };
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
    ...mapGetters("salesOrders", {
      cart: "cart",
    }),
  },

  data() {
    return {};
  },

  methods: {
    async refreshCart() {
      await this.$store.dispatch("salesOrders/cart", this.me.id);

      const cart = this.$store.getters["salesOrders/cart"];

      for (const order of cart) {
        for (const item of order.itemsBookings) {
          const booking = item.salesOrderable;
          const expiry = moment()
            .utc()
            .diff(moment(booking.updated_at), "minutes");
          const remainingMinutes = cartDeaultDelay - expiry;

          if (
            cartDefaultExpirations.includes(expiry) ||
            remainingMinutes <= 0
          ) {
            swal.fire({
              title: remainingMinutes
                ? this.$t("COMMON.CART_CHRONO_TITLE")
                : this.$t("COMMON.CART_CHRONO_END_TITLE"),
              type: "info",
              confirmButtonText: this.$t("COMMON.CLOSE"),
              html: remainingMinutes
                ? this.$t("COMMON.MINUTES_WARNING", [
                    booking.code,
                    remainingMinutes,
                  ])
                : this.$t("COMMON.MINUTES_EXPIRED", [booking.code]),
              customClass: {
                popup: "default-popup chrono",
                header: !remainingMinutes ? "end" : "",
              },
              buttonsStyling: false,
            });
          }
        }
      }
    },
  },

  destroyed() {
    clearInterval(this.refreshCart);
  },

  async mounted() {
    swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        swal.showLoading();
      },
    });

    if (this.$store.getters["isAuthenticated"]) {
      await this.$store.dispatch("profile/me");
      await this.$store.dispatch("organizations/initState");

      setInterval(this.refreshCart, _30_seconds);
    } else {
      console.log("NOT LOGGED");
    }
  },

  watch: {
    appConfig: {
      handler: function (val) {
        if (val) {
          swal.close();
        }
      },
      deep: true,
    },
    me: {
      handler: function (val) {
        if (val) {
          this.refreshCart();
        }
      },
    },
    deep: true,
  },
};
</script>

import service from "@/store/services/sales-invoices-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  salesInvoice: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, salesInvoice) => {
    state.salesInvoice = salesInvoice;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((salesInvoice) => {
      commit("SET_RESOURCE", salesInvoice);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((salesInvoice) => {
      commit("SET_RESOURCE", salesInvoice);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((salesInvoice) => {
      commit("SET_RESOURCE", salesInvoice);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  validate({ commit, dispatch }, params) {
    return service.validate(params, this.$axios).then((salesInvoice) => {
      commit("SET_RESOURCE", salesInvoice);
    });
  },

  cancel({ commit, dispatch }, params) {
    return service.cancel(params, this.$axios).then((salesInvoice) => {
      commit("SET_RESOURCE", salesInvoice);
    });
  },
  chargeDifference({ commit, dispatch }, {invoiceId, data}) {
    return service.chargeDifference(invoiceId, data, this.$axios);
  },
  refund({ commit, dispatch }, invoiceId) {
    return service.refund(invoiceId, this.$axios);
  },
  sendMailToCustomer({ commit, dispatch }, invoiceId) {
    return service.sendMailToCustomer(invoiceId)
  },

  previewFromUpdatedBooking({ commit, dispatch }, data) {
    return service.previewFromUpdatedBooking({
              invoiceId: data.invoiceId, 
              newBooking: data.payload.newBooking
            },
            this.$axios
          )
  },

  processToUpdate({ commit, dispatch }, data) {
    return service.processToUpdate(data,this.$axios)
  }
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  salesInvoice: (state) => state.salesInvoice,
  url: (state) => state.url,
};

const salesInvoices = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default salesInvoices;
